// function makeApiRequest(data) {
//     var xmlhttp = new XMLHttpRequest();

//     xmlhttp.open("POST", "https://76qgsh5at9.execute-api.eu-north-1.amazonaws.com/prod/email");

//     xmlhttp.setRequestHeader("Content-Type", "application/json");

//     xmlhttp.send(JSON.stringify(data));
//     xmlhttp.onreadystatechange = function () {
//       if (xmlhttp.readyState === 4) {
//         var response = JSON.parse(xmlhttp.responseText);
//         console.log(response);
//         if (xmlhttp.status === 200) {
//           console.log('successful');
//           // document.getElementById("contact-form").innerHTML = "<h1>Thank you for your message/feedback<br>our team will get back to you soon!</h1>";
//         } else {
//           console.log('failed');
//         }
//       }
//     }
//   }
const endpoint =
  "https://44pfqdqud1.execute-api.eu-north-1.amazonaws.com/prod/";
//const endpoint = "https://76qgsh5at9.execute-api.eu-north-1.amazonaws.com/prod/"
function post(data, path) {
  return new Promise((resolve, reject) => {
    var xmlhttp = new XMLHttpRequest();

    xmlhttp.open("POST", endpoint + path);

    xmlhttp.setRequestHeader("Content-Type", "application/json");

    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4) {
        var response = JSON.parse(xmlhttp.responseText);
        if (xmlhttp.status === 200) {
          resolve(response);
        } else {
          reject(new Error("API request failed"));
        }
      }
    };
    xmlhttp.send(JSON.stringify(data));
  });
}
export function get(data, path) {
  return new Promise((resolve, reject) => {
    var xmlhttp = new XMLHttpRequest();

    var url = endpoint + path + '?' + serializeParams(data);

    xmlhttp.open("GET", url);

    xmlhttp.setRequestHeader("Content-Type", "application/json");

    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4) {
        var response = JSON.parse(xmlhttp.responseText);
        if (xmlhttp.status === 200) {
          resolve(response);
        } else {
          reject(new Error("API request failed"));
        }
      }
    };
    xmlhttp.send(JSON.stringify(data));
  });
}
function serializeParams(params) {
  return Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
}

// Export the function to make it available for other modules
export default post;
