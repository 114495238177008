<template>
    <the-design-hero v-if="designHero"></the-design-hero>
    <the-frontend-hero v-if="frontendHero"></the-frontend-hero>
    <the-hero v-else></the-hero>
    <section>
        <post-gallery :showCategoryFilter="false"></post-gallery>
    </section>
</template>

<script>
import TheHero from '../components/UI/The/TheHero.vue';
import TheDesignHero from '../components/UI/The/TheDesignHero.vue'
import TheFrontendHero from '../components/UI/The/TheFrontendHero.vue'
import PostGallery from '../components/UI/PostGallery2.vue';

export default {
    components: {
        TheHero,
        PostGallery,
        TheDesignHero,
        TheFrontendHero
    },
    computed: {
        designHero() {
            return this.$store.state.contentType == 2;
        },
        frontendHero() {
            return this.$store.state.contentType == 3;
        }
    }
}
</script>

<style scoped>
section {
    min-height: 100vh;
 /* background-color: var(--color-gray-19); */
}</style>