<template>
    <section class="grid-container wrapper">
        <div class="row">
            <div class="column large-12">
                <article class="s-content__entry">
                    <div class="s-content__media">
                        <img src="images/thumbs/contact/contact-1050.jpg" srcset="images/thumbs/contact/contact-2100.jpg 2100w, 
                                images/thumbs/contact/contact-1050.jpg 1050w, 
                                images/thumbs/contact/contact-525.jpg 525w" sizes="(max-width: 2100px) 100vw, 2100px"
                            alt="">
                    </div>
                    <div class="s-content__entry-header">
                        <h1 class="s-content__title">Get In Touch With Me.</h1>
                    </div> 
                    <div class="s-content__primary">
                        <div class="s-content__page-content">
                            <p class="lead">
                                As a lifelong learner, I am always looking for new opportunities to expand my knowledge and
                                skills, whether it's through collaborating with others on exciting projects or taking on
                                challenging new projects on my own. If you are interested in working with me, or just want
                                to learn more about what I do, please don't hesitate to get in touch. I look forward to
                                hearing from you!
                            </p>
                            <br>
                            <form name="cForm" id="cForm" class="s-content__form" method="post" action="">
                                <fieldset>
                                    <div class="form-field">
                                        <input name="cName" type="text" id="cName" class="h-full-width h-remove-bottom"
                                            placeholder="Your Name" value="">
                                    </div>
                                    <div class="form-field">
                                        <input name="cEmail" type="text" id="cEmail" class="h-full-width h-remove-bottom"
                                            placeholder="Your Email" value="">
                                    </div>
                                    <div class="form-field">
                                        <input name="cWebsite" type="text" id="cWebsite"
                                            class="h-full-width h-remove-bottom" placeholder="Website" value="">
                                    </div>
                                    <div class="message form-field">
                                        <textarea name="cMessage" id="cMessage" class="h-full-width"
                                            placeholder="Your Message"></textarea>
                                    </div>
                                    <br>
                                    <button type="submit" class="submit btn btn--primary h-full-width">Submit</button>
                                </fieldset>
                            </form> <!-- end form -->

                            <div class="row block-large-1-2 block-tab-full s-content__blocks">
                                <div class="column">
                                    <h4>Contact Info</h4>
                                    <p>
                                        hello@tomandersson.se <br>
                                        Phone: (+47) 76 88 38 419
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<style scoped>
.wrapper {
    margin-top: 8.8rem;
}


</style>