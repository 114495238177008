<template>
  <the-header></the-header>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <main>
    <router-view></router-view>
  </main>
  <cookie-consent></cookie-consent>
  <!-- <call-to-action></call-to-action> -->
  <!--<HelloWorld msg="Welcome to Your Vue.js App" />-->
  <the-footer></the-footer>
</template>

<script>
import TheHeader from './components/UI/The/TheHeader.vue'
import TheFooter from './components/UI/The/TheFooter.vue'
import CookieConsent from './components/UI/CookieConsent.vue'
// import CallToAction from './components/UI/CallToAction.vue'

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    CookieConsent
  }
}
</script>

<style>
/* @import 'src/assets/css/app.css';
@import 'src/assets/css/foundation.min.css';
@import 'src/assets/css/foundation.css'; */
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/

@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=DM+Serif+Text&family=Inter:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --font-1: "Inter", sans-serif;
  --font-2: "DM Serif Text", serif;
  --font-3: "DM Serif Display", serif;

  /* monospace
     */
  --font-mono: Consolas, "Andale Mono", Courier, "Courier New", monospace;
  --amplify-primary-color: #d0d1d1;
  --amplify-primary-contrast: #000000;
  --amplify-primary-tint: #3f6060;
  --amplify-primary-shade: #2dba89;
  --amplify-background-color: #d0d1d1;
  --amplify-colors-brand-primary-80: hsla(358, 65%, 66%, 1);
}

/* ------------------------------------------------------------------- 
 * ## colors
 * ------------------------------------------------------------------- */
:root {

  /* color-1(#0A369D)
     * color-2(#C1292E)
     */
  --color-1: hsla(222, 88%, 33%, 1);
  --color-2: hsla(358, 65%, 46%, 1);

  /* theme color variations
     */
  --color-1-lighter: hsla(222, 88%, 53%, 1);
  --color-1-light: hsla(222, 88%, 43%, 1);
  --color-1-dark: hsla(222, 88%, 23%, 1);
  --color-1-darker: hsla(222, 88%, 13%, 1);
  --color-2-lighter: hsla(358, 65%, 66%, 1);
  --color-2-light: hsla(358, 65%, 56%, 1);
  --color-2-dark: hsla(358, 65%, 36%, 1);
  --color-2-darker: hsla(358, 65%, 26%, 1);

  /* feedback colors
     * color-error(#ffd1d2), color-success(#c8e675), 
     * color-info(#d7ecfb), color-notice(#fff099)
     */
  --color-error: hsla(359, 100%, 91%, 1);
  --color-success: hsla(76, 69%, 68%, 1);
  --color-info: hsla(205, 82%, 91%, 1);
  --color-notice: hsla(51, 100%, 80%, 1);
  --color-error-content: hsla(359, 50%, 50%, 1);
  --color-success-content: hsla(76, 29%, 28%, 1);
  --color-info-content: hsla(205, 32%, 31%, 1);
  --color-notice-content: hsla(51, 30%, 30%, 1);

  /* shades 
     * generated using 
     * Tint & Shade Generator 
     * (https://maketintsandshades.com/)
     */
  --color-black: #000000;
  --color-gray-19: #0a0a0a;
  --color-gray-18: #141414;
  --color-gray-17: #1e1e1e;
  --color-gray-16: #282828;
  --color-gray-15: #323333;
  --color-gray-14: #3b3d3d;
  --color-gray-13: #454747;
  --color-gray-12: #4f5151;
  --color-gray-11: #595b5b;
  --color-gray-10: #636565;
  --color-gray-9: #737474;
  --color-gray-8: #828484;
  --color-gray-7: #929393;
  --color-gray-6: #a1a3a3;
  --color-gray-5: #b1b2b2;
  --color-gray-4: #c1c1c1;
  --color-gray-3: #d0d1d1;
  --color-gray-2: #e0e0e0;
  --color-gray-1: #eff0f0;
  --color-white: #ffffff;

  /* text
     */
  --color-text: var(--color-gray-16);
  --color-text-dark: var(--color-black);
  --color-text-light: var(--color-gray-7);
  --color-placeholder: var(--color-gray-7);

  /* buttons
     */
  --color-btn: var(--color-gray-3);
  --color-btn-text: var(--color-black);
  --color-btn-hover: var(--color-black);
  --color-btn-hover-text: var(--color-white);
  --color-btn-primary: var(--color-black);
  --color-btn-primary-text: var(--color-white);
  --color-btn-primary-hover: var(--color-gray-16);
  --color-btn-primary-hover-text: var(--color-white);
  --color-btn-stroke: var(--color-black);
  --color-btn-stroke-text: var(--color-black);
  --color-btn-stroke-hover: var(--color-black);
  --color-btn-stroke-hover-text: var(--color-white);

  /* others
     */
  --color-bg: var(--color-gray-1);
  --color-border: #eeeeee;
}

/* ------------------------------------------------------------------- 
 * ## vertical spacing and typescale
 * ------------------------------------------------------------------- */
:root {

  /* spacing
     * base font size: 18px 
     * vertical space unit : 32px
     */
  --base-size: 62.5%;
  --base-font-size: 1.8rem;
  --space: 3.2rem;

  /* vertical spacing 
     */
  --vspace-0_125: calc(0.125 * var(--space));
  --vspace-0_25: calc(0.25 * var(--space));
  --vspace-0_5: calc(0.5 * var(--space));
  --vspace-0_75: calc(0.75 * var(--space));
  --vspace-0_875: calc(0.875 * var(--space));
  --vspace-1: calc(var(--space));
  --vspace-1_25: calc(1.25 * var(--space));
  --vspace-1_5: calc(1.5 * var(--space));
  --vspace-1_75: calc(1.75 * var(--space));
  --vspace-2: calc(2 * var(--space));
  --vspace-2_5: calc(2.5 * var(--space));
  --vspace-3: calc(3 * var(--space));
  --vspace-3_5: calc(3.5 * var(--space));
  --vspace-4: calc(4 * var(--space));
  --vspace-4_5: calc(4.5 * var(--space));
  --vspace-5: calc(5 * var(--space));

  /* type scale
     * ratio 1:2 | base: 18px
     * -------------------------------------------------------
     *
     * --text-display-3 = (77.40px)
     * --text-display-2 = (64.50px)
     * --text-display-1 = (53.75px)
     * --text-xxxl      = (44.79px)
     * --text-xxl       = (37.32px)
     * --text-xl        = (31.10px)
     * --text-lg        = (25.92px)
     * --text-md        = (21.60px)
     * --text-size      = (18.00px) BASE
     * --text-sm        = (15.00px)
     * --text-xs        = (12.50px)
     *
     * -------------------------------------------------------
     */
  --text-scale-ratio: 1.2;
  --text-size: var(--base-font-size);
  --text-xs: calc((var(--text-size) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-display-1: calc(var(--text-xxxl) * var(--text-scale-ratio));
  --text-display-2: calc(var(--text-display-1) * var(--text-scale-ratio));
  --text-display-3: calc(var(--text-display-2) * var(--text-scale-ratio));

  /* default button height
     */
  --vspace-btn: var(--vspace-2);
}

html {
  font-size: var(--base-size);
}

html,
body {
  height: 100%;
}

body {
  background: var(--color-bg);
  font-family: var(--font-1);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: normal;
  line-height: var(--vspace-1);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: var(--font-1);
}

/* ------------------------------------------------------------------- 
 * ## medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {}

/* ------------------------------------------------------------------- 
 * ## tablets
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
  .hide-on-tablet {
    display: none;
  }
}

/* ------------------------------------------------------------------- 
 * ## mobile devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}

/* ------------------------------------------------------------------- 
 * ## small mobile devices <= 400px
 * ------------------------------------------------------------------- */
@media screen and (max-width: 400px) {
  .hide-on-mobile {
    display: none;
  }
}

/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.montserrat-lead {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: italic;
}

.montserrat-link {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: light;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  --input-height: var(--vspace-2);
  --input-line-height: var(--vspace-1);
  --input-vpadding: calc((var(--input-height) - var(--input-line-height)) / 2);
  display: block;
  height: var(--input-height);
  padding: var(--input-vpadding) 0 calc(var(--input-vpadding) - .1rem);
  border: 0;
  outline: none;
  color: var(--color-text);
  font-family: var(--font-1);
  font-size: calc(var(--text-size) * 0.7778);
  line-height: var(--input-line-height);
  max-width: 100%;
  background: transparent;
  border-bottom: 1px solid var(--color-gray-8);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.0);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.0);
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  color: var(--color-black);
  border-bottom: 2px solid var(--color-black);
}</style>
