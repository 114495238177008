// src/mixins/metaMixin.js
export default {
        updateMetaTags(title, description, keywords) {
            document.title = title || 'Default Title';
            this.updateMetaTag('description', description || 'Default description');
            this.updateMetaTag('keywords', keywords || 'default, keywords');
        },
        updateMetaTag(name, content) {
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            if (metaTag) {
                metaTag.setAttribute('content', content);
            } else {
                metaTag = document.createElement('meta');
                metaTag.setAttribute('name', name);
                metaTag.setAttribute('content', content);
                document.head.appendChild(metaTag);
            }
        },
};
