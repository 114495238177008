<template>
    <div>
        <h5>Recent posts</h5>
        <article-thumbnail-vue v-for="(post, index) in reversedItems" :key="index" :post="post"
            :isSmall="true"></article-thumbnail-vue>
    </div>
</template>

<script>
import ArticleThumbnailVue from './ArticleThumbnail.vue'

export default {
    components: {
        ArticleThumbnailVue
    },
    props: {
        posts: Object
    },
    computed: {
        reversedItems() {            
            const sortedPosts = this.posts.slice().sort((a, b) => {
                const dateA = new Date(a.date); // Assuming the date field is named 'date'
                const dateB = new Date(b.date);
                return dateB - dateA; // Sort in descending order
            });
            return sortedPosts.slice(0,3);
        },
    }
}
</script>

<style scoped>
.bricks-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
</style>