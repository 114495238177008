<template>
    <div>
        <div class="gallery wrapper">
            <div v-for="(columnItems, columnIndex) in columns" :key="columnIndex" class="gallery__column">
                <div>
                    <a v-for="(item, index) in columnItems" :key="index" target="_blank" class="gallery__link">
                        <figure class="gallery__thumb" :class="{ 'clicked': selectedImage === item }">
                            <img @click="showImage(item)" :src="item.imageSrc" :alt="item.altText" class="gallery__image">
                            <figcaption class="gallery__caption">{{ item.caption }}</figcaption>
                            <figcaption class="gallery__description">{{ item.description }}</figcaption>
                        </figure>
                    </a>
                </div>
            </div>

            <!-- Overlay -->
            <div v-if="selectedImage" class="overlay">
                <img :src="selectedImage.imageSrc" class="img">
                <!-- <div class="text">{{ selectedImage.description }}</div> -->
                <span class="close-icon" @click="closeOverlay">&times;</span>
            </div>
        </div>
        <base-button v-if="showLoadMoreButton" class="load-more-button" @clickEve="loadMoreImages">Load More</base-button>
    </div>
</template>

<script>
import images from "../assets/images.json"
import BaseButton from "@/components/UI/BaseComponents/BaseButton.vue";

export default {
    components: {
        BaseButton
    },
    data() {
        return {
            selectedImage: null,
            images: images,
            selectedImageIndex: null,
            numImagesLoaded: 8, // Initial number of images loaded
            imagesPerPage: 8 // Number of images to load per page
        };
    },
    created() {
        const that = this;
        document.addEventListener('keyup', function (evt) {
            if (evt.key === 'Escape') {
                that.closeOverlay();
            }
            if (evt.keyCode === 39) {
                that.nextImage();
            }
            if (evt.keyCode === 37) {
                that.prevImage();
            }
        });
    },
    computed: {
        // columns() {
        //     const totalItems = this.images.images.length;
        //     const numColumns = 4;
        //     const itemsPerColumn = Math.floor(totalItems / numColumns);
        //     const remainder = totalItems % numColumns;
        //     const columns = [];

        //     let start = 0;
        //     let end = 0;
        //     for (let i = 0; i < numColumns; i++) {
        //         end = start + itemsPerColumn + (i < remainder ? 1 : 0);
        //         columns.push(this.images.images.slice(start, end));
        //         start = end;
        //     }

        //     return columns;
        // },
        columns() {
            //const totalItems = this.images.images.length;
            const numColumns = 4;
            const itemsPerColumn = Math.floor(this.numImagesLoaded / numColumns);
            const remainder = this.numImagesLoaded % numColumns;
            const columns = [];

            let start = 0;
            let end = 0;
            for (let i = 0; i < numColumns; i++) {
                end = start + itemsPerColumn + (i < remainder ? 1 : 0);
                columns.push(this.images.images.slice(start, end));
                start = end;
            }

            return columns;
        },
        showLoadMoreButton() {
            return this.numImagesLoaded < this.images.images.length;
        }
    },
    watch: {
        // eslint-disable-next-line
        selectedImage(newValue, oldValue) {
            if (newValue) {
                this.selectedImageIndex = this.images.images.findIndex(image => image.uid === newValue.uid);
            }
        }
    },
    methods: {
        getImgUrl(data) {
            console.log("test" + data);

            return require(`@/assets/portfolioassets/${data}`);
        },
        nextImage() {
            if (this.selectedImageIndex < this.images.images.length - 1) {
                this.selectedImage = this.images.images[this.selectedImageIndex + 1];
            }
        },
        prevImage() {
            if (this.selectedImageIndex > 0) {
                this.selectedImage = this.images.images[this.selectedImageIndex - 1];
            }
        },
        showImage(image) {
            this.selectedImage = image;
        },
        closeOverlay() {
            this.selectedImageIndex = null;
            this.selectedImage = null;
        },
        loadMoreImages() {
            this.numImagesLoaded += this.imagesPerPage;
        }
    }
};
</script>


<style scoped>
.wrapper {
    margin-top: 8.8rem;
    padding-top: 50px;
}

.gallery {
    display: flex;
    padding: 2px;
    transition: .3s;
}

.gallery:hover .gallery__image {
    filter: grayscale(1);
}

.gallery__column {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.gallery__link {
    margin: 2px;
    overflow: hidden;
}

.gallery__link:hover .gallery__image {
    filter: grayscale(0);
}

.gallery__link:hover .gallery__caption {
    opacity: 1;
}

.gallery__thumb {
    position: relative;
    transition: 1s ease-in;
}

.gallery__thumb.clicked {
    /*position: absolute;*/
    z-index: 100;
    top: 0;
    left: 0;
}

.gallery__image {
    display: block;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 100%;
    transition: .3s;
    transform: scale(0.95);

}

.gallery__image:hover {
    transform: scale(1);
}

.gallery__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px 15px 15px;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: white;
    opacity: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(255, 255, 255, 0) 100%);
    transition: .3s;
}

.gallery__description {
    display: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Greyed-out background with transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Ensure the overlay appears above other content */
}

.img {
    z-index: 1001;
    /* Ensure the image appears above the overlay background */
    max-height: 95%;
    max-width: 95%;
}

.text {
    z-index: 1001;
    /* Ensure the text appears above the overlay background */
    color: white;
    /* Set text color to be visible on the greyed-out background */
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    cursor: pointer;
    font-size: 24px;
    z-index: 1002;
    /* Ensure the close icon appears above everything else */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.text {
    color: white;
    margin-top: 20px;
    text-align: center;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    cursor: pointer;
    font-size: 24px;
}
</style>