<template>
    <header>
        <div class="inner">
            <div class="logo">
                <router-link class="thumb-link" aria-label="Home" :to="'/'">

                    <img src="../../../assets/Logo1.png" class="header_logo" alt="Home Page">
                </router-link>

            </div>
            <!-- <div class="burger" @click.self="toggleMenu"></div> -->
            <div class="burger">
                <button v-on:click="toggleMenu" class="burger-icon">&#9776;</button>
            </div>
            <nav class="desktop">
                <router-link active-class="active" exact-active-class="active" :to="'/'">Home</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/blog'">Blog</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/portfolio'">Portfolio</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/about'">About</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/contact'">Contact</router-link>
            </nav>
            <nav class="mobile" :class="{ open: isMenuOpen, close: !isMenuOpen && animationActive }">
                <router-link active-class="active" exact-active-class="active" :to="'/'"
                    v-on:click="closeMenu">Home</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/blog'"
                    v-on:click="closeMenu">Blog</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/portfolio'"
                    v-on:click="closeMenu">Portfolio</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/about'"
                    v-on:click="closeMenu">About</router-link>
                <router-link active-class="active" exact-active-class="active" :to="'/contact'"
                    v-on:click="closeMenu">Contact</router-link>
            </nav>
        </div>
    </header>
</template>
<script>
export default {
    data() {
        return {
            isMenuOpen: false,
            animationActive: false
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu() {
            console.log("close")
            this.animationActive = true;
            this.isMenuOpen = false;
            setTimeout(() => {
                this.animationActive = false;
            }, 500); // Duration of the closing animation
        }
    },

};
</script>
<style scoped lang="scss">
header {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7.5rem;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.256);

    .inner {
        max-width: 1060px;
        margin: 0 auto;
        display: flex;
        height: 70px;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .logo {
        display: block;
        width: 76px;
        height: 90px;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        text-align: center;
        z-index: 100;

        img {
            width: 45px;
            margin-top: 10px;
        }
    }


    @-webkit-keyframes moveDown {
        from {
            opacity: 0;
            padding-top: 0rem;

        }

        to {
            padding-top: 10rem;
            opacity: 1;

        }
    }

    @keyframes moveDown {
        from {
            opacity: 0;
            padding-top: 0rem;

        }

        to {
            padding-top: 10rem;
            opacity: 1;
        }
    }

    @keyframes moveUp {
        from {
            opacity: 1;
            padding-top: 10rem;
        }

        to {
            opacity: 0;
            padding-top: 0rem;
        }
    }

    @-webkit-keyframes moveUp {
        from {
            opacity: 1;
            padding-top: 10rem;
        }

        to {
            opacity: 0;
            padding-top: 0rem;
        }
    }

    nav {
        display: none;
        transition: all 5.0s ease-in-out;

        &.mobile {
            background-color: transparent;
            padding-top: 0rem;
            -webkit-animation: moveDown 1.5s reverse;
            animation: moveDown 1.5s reverse;

        }

        &.mobile.close {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 110vh;
            background: rgba(0, 0, 0, 0.9);
            padding-top: 10rem;
            padding-left: 3rem;
            animation: moveUp .5s forwards;
            -webkit-animation: moveUp .5s forwards;
        }

        &.mobile.open {
            -webkit-animation: moveDown 1.5s;
            animation: moveDown 1.5s;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 110vh;
            background: rgba(0, 0, 0, 0.9);
            padding-top: 10rem;
            padding-left: 3rem;

            a {
                font-size: 22px;

                &.active {
                    padding-left: 10px;
                    border-left: 2px solid;
                }
            }

            //max-height: 300px; /* Adjust based on your menu content */
        }


        a {
            font-family: 'Arial', serif;
            font-size: 12px;
            color: white;
            text-transform: uppercase;
            letter-spacing: 3px;
            text-decoration: none;
            margin: 0 18px;

            &.active,
            &:hover {
                color: #8c8c8e;
            }
        }

        &.desktop {
            @media screen and (min-width: 800px) {
                display: block;
            }
        }

    }

    .burger {
        display: block;
        position: relative;

        top: -6px;
        padding-left: 30px;

        .burger-icon {
            font-size: 40px;
            cursor: pointer;
            color: #fff;
            background-color: transparent;
        }

        @media screen and (min-width: 800px) {
            display: none;
        }
    }
}
</style>
