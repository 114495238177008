<template>
    <div class="login">
        <!-- <amplify-authenticator></amplify-authenticator> -->
        <authenticator :form-fields="formFields">
            <template v-slot="{ user, signOut }">
                <h1>Hello {{ user.username }}!</h1>
                <button @click="signOut">Sign Out</button>
            </template>
        </authenticator>
    </div>
</template>

<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter Your Email Here',
      isRequired: true,
      label: 'Email:'
    },
  },
  signUp: {
    username: {
      placeholder: 'Enter Your Email Here',
      isRequired: true,
      label: 'Email:',
      order: 1,

    },
    name:{
        placeholder: 'Name',
        label: 'Name:',
        order: 2,

    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 4,
    },
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 3,
    },
  },
}
</script>

<style scoped>
.login{
    min-height: 100vh;
    margin-top: 8.8rem;
}

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    --btn-height: var(--vspace-btn);
    display: inline-block;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: var(--text-xs);
    text-transform: uppercase;
    letter-spacing: .4em;
    height: var(--btn-height);
    line-height: calc(var(--btn-height) - .4rem);
    padding: 0 3.2rem;
    margin: 0 .4rem 1.6rem 0;
    color: var(--color-btn-text);
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-btn);
    border: 0.2rem solid var(--color-btn);
    width: 100%;
}

input[type="email"],
input[type="text"],
input[type="password"] {
    --input-height: vspace(1.75);
    background-color: transparent;
    border: 1px solid var(--color-text);
    padding: .8rem 2rem;
    width: 100%;
    text-align: center;
    margin-bottom: var(--vspace-0_5);
    --input-line-height: var(--vspace-1);
    --input-vpadding: calc((var(--input-height) - var(--input-line-height)) / 2);
    display: block;
    height: var(--input-height);
}

input[type="email"]:focus,
input[type="text"]:focus,
input[type="password"]:focus {
    border:0;
}
button{
  --btn-height: var(--vspace-btn) !important;
    display: inline-block;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: var(--text-xs);
    text-transform: uppercase;
    letter-spacing: .4em;
    height: var(--btn-height);
    line-height: calc(var(--btn-height) - .4rem);
    padding: 0 3.2rem;
    margin: 0 0.4rem 1.6rem 0;
    color: var(--color-btn-text);
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-btn);
    border: 0.2rem solid var(--color-btn);
    width: 100%;
}

.amplify-button[data-variation='link']:hover {
  background: none;
  text-decoration: underline;
}
</style>
<!-- <script>
    export default {
        name: 'loginPage'
    }
</script> -->