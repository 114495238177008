<template>
    <div class="s-bricks">
        <div class="masonry">
            <div v-if="posts && filteredposts">
                <base-categorization class="hide-on-mobile" v-show="showCategoryFilter" @category-change="handlePostFilter"
                    :max="4" :items="categories"></base-categorization>
                <div class="bricks-wrapper">
                    <article-thumbnail-vue v-for="(post, index) in displayedPosts" :key="index"
                        :post="post"></article-thumbnail-vue>
                </div>
            </div>
            <base-pagination :current-page="currentPage" :total-pages="totalPages"
                @page-change="handlePageChange"></base-pagination>
        </div>
    </div>
</template>

<script>
import ArticleThumbnailVue from './ArticleThumbnail.vue'
import BasePagination from './BaseComponents/BasePagination.vue'
import BaseCategorization from "./BaseComponents/BaseCategorization.vue"
import { loadJsonFromS3 } from '../../js/s3Service.js';


export default {
    components: {
        ArticleThumbnailVue,
        BasePagination,
        BaseCategorization
    },
    props: {
        filter: {
            type: String,
            default: null
        },
        showCategoryFilter: {
            type: Boolean,
            default: true
        },
        itemsPerPage: {
            type: Number,
            default: 8,
        },
    },
    data() {
        return {
            currentPage: 1,
            posts: null,
            filteredposts: null,
        };
    },
    watch: {
        filter: function (newVal, oldVal) {
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            this.handleCategories(newVal);
        },
    },
    created() {
        this.loadJson();
    },
    computed: {
        categories() {
            const categoryCounts = {};
            if (this.posts) {
                this.posts.forEach(post => {
                    post.categories.forEach(category => {
                        categoryCounts[category] = (categoryCounts[category] || 0) + 1;
                    });
                });
            }

            return categoryCounts;
        },
        totalPages() {
            if (this.filteredposts) {
                return Math.ceil(this.filteredposts.posts.length / this.itemsPerPage);
            }
            return 0;
        },
        displayedPosts() {
            if (this.filteredposts) {

                const sortedPosts = this.filteredposts.posts.slice().sort((a, b) => {
                    const dateA = new Date(a.date); // Assuming the date field is named 'date'
                    const dateB = new Date(b.date);
                    return dateB - dateA; // Sort in descending order
                });

                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return sortedPosts.slice(start, end);
            }
            return {
                "posts": []
            }
        }
    },
    methods: {
        async loadJson() {
            try {
                var postsFromS3 = await loadJsonFromS3('https://websitebuckettom-assets.s3.eu-north-1.amazonaws.com/posts/posts.json');
                this.posts = postsFromS3
                this.filteredposts = { posts: postsFromS3 }
                this.handleCategories(this.filter);
            } catch (error) {
                console.error('Error loading JSON from S3:', error);
            }
        },
        handlePageChange(newPage) {
            this.currentPage = newPage;
        },
        handleCategories(filter) {
            if (filter && filter.length > 0) {
                const categories = filter.split(',');
                this.filteredposts.posts = this.posts.filter((item) => {
                    return item.categories.some((category) => categories.includes(category));
                });
            } else {
                this.filteredposts.posts = this.posts;
            }
        },
        handlePostFilter(category) {
            this.filteredposts.posts = this.posts.posts.filter((item) => item.categories.includes(category));
        }
    },
}
</script>
  
<style scoped>
.bricks-wrapper {
    display: flex;
    flex-wrap: wrap;
}
</style>
  