<template>
  <div class="wrapper">
    <section>

      <div class="grid-x">
        <div class="cell large-9 value">
          <span class="headline_text">Consistent design solutions for your needs</span>
          <h4>Navigating the intersection of art and function to transform your brand with the reliable design. I offer
            tailored plans that gives a consistent flow of creative solutions, ensuring that your brand stays ahead of the
            curve. </h4>
          <base-button type="cta">Start now</base-button>
        </div>
        <div class="cell large-1 hero__social hide-on-mobile">
          <p>Follow</p>
          <span></span>
          <ul class="hero__social-icons">
            <li><a href="https://www.linkedin.com/in/tom-andersson-4a7189113/" target=”_blank”><i class="fab fa-linkedin"
                  aria-hidden="true"></i></a></li>
            <li><a href="https://www.instagram.com/lifearray/" target=”_blank”><i class="fab fa-instagram"
                  aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
      <div class="grid-x">
        <div class="cell large-12 spinner-container">
          <div class="rounded-text rotating">
            <svg viewBox="0 0 200 200">
              <path id="textPath" d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0" transform="translate(100,100)"
                fill="none" stroke-width="0"></path>
              <g font-size="13.1px">
                <text text-anchor="start">
                  <textPath class="coloring" xlink:href="#textPath" startOffset="0%">• Tom andersson Design: Art • Web
                    Design •
                    Interaction and UX design</textPath>
                </text>
              </g>
            </svg>
            <div class="static-text coloring" @click="goto('recentwork')">Explore</div>
          </div>
        </div>
      </div>
    </section>
    <section class="recent_work" ref="recentwork">
      <div class="down"><!--  -->
        <span class="headtext">Recent work.</span>
        <div>
          <transition name="fade">
            <article class="grid-x work_item" v-if="show">
              <div class="cell large-8 medium-8 small-12">
                <div class="text">
                  <a href="">PlanetProtégé Landing page</a>
                </div>
              </div>
              <div class="cell large-3 medium-3 small-12">
                <img src="../../../assets/portfolioassets/pandetp.png">
              </div>
            </article>
          </transition>
          <transition name="fade">
            <article class="grid-x work_item" v-if="show">
              <div class="cell large-8 medium-8 small-12">
                <div class="text">
                  <a href="">PlanetProtégé Landing page</a>
                </div>
              </div>
              <div class="cell large-3 medium-3 small-12">
                <img src="../../../assets/portfolioassets/pandetp.png">
              </div>
            </article>
          </transition>
          <transition name="fade">
            <article class="grid-x work_item" v-if="show">
              <div class="cell large-8 medium-8 small-12">
                <div class="text">
                  <a href="">PlanetProtégé Landing page</a>
                </div>
              </div>
              <div class="cell large-3 medium-3 small-12">
                <img src="../../../assets/portfolioassets/pandetp.png">
              </div>
            </article>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseButton from "../BaseComponents/BaseButton.vue"

export default {
  components: {
    BaseButton
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
    goto(refName) {
      var el = this.$refs[refName];
      if (el) {
        this.show = !this.show;
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
}
</script>

<style scoped>
.wrapper {
  margin-top: 8.8rem;
  scroll-behavior: smooth;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: var(--text-xl);
}

h4 {
  font-size: var(--text-md);
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

.value {
  padding: 10rem;
}

.headtext {
  color: var(--color-gray-10);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 5.8rem;
  letter-spacing: +.05em;
  display: block;
  font-weight: 200;
}

.headline_text {
  color: var(--color-gray-10);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 5.8rem;
  letter-spacing: +.15em;
  font-weight: 500;
  max-width: 80vw;
}

section {
  width: 100%;
  min-height: 100vh;
  /* min-height: calc(25.5 * var(--space)); */
  border-bottom: 1px solid rgb(98, 98, 98);
  /* Adjust the color and thickness as needed */
  background-color: #f9f8eb;
}

.spinner-container {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  overflow: hidden;
  display: grid;
  place-items: end;
}

.static-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  /* Adjust the font size as needed */
  color: var(--color-gray-14);
  /* Adjust the color as needed */
}

.rounded-text {
  width: 18.625rem;
  height: 18.625rem;
  text-transform: uppercase;
  transform: rotate(-103deg);
  color: #fff;
  font-weight: bold;
  /* z-index: -1; */
  font-family: Raleway;
  cursor: pointer;
}

.white {
  fill: #fff;
}

.yellow {
  fill: #ffda2a;
}

.green {
  fill: #00d3b9;
}

@keyframes rotating {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.rotating {
  animation: rotating 12s linear infinite;
}

@keyframes coloring {
  0% {
    fill: var(--color-gray-7);
    color: var(--color-gray-7);
  }

  35% {
    fill: var(--color-gray-9);
    color: var(--color-gray-9);
  }

  75% {
    fill: var(--color-gray-14);
    color: var(--color-gray-14);
  }

  100% {
    fill: var(--color-gray-19);
    color: var(--color-gray-19);
  }
}

.coloring {
  animation: coloring 12s linear infinite;
}

.coloring:hover {
  animation: none;
  fill: var(--color-gray-19);
  color: var(--color-gray-19);
}

.hero__social {
  font-size: var(--text-xs);
  font-weight: 400;
  color: var(--color-gray-19);
  position: absolute;
  top: 40%;
  right: 0;
}

.hero__social a {
  color: var(--color-gray-19);
}

.hero__social span {
  display: block;
  width: 1px;
  height: 3.6rem;
  background-color: var(--color-gray-19);
  margin: 3.6rem auto 4.8rem;
}

.hero__social p {
  line-height: 1;
  margin: 0;
  position: relative;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hero__social-icons {
  list-style: none;
  margin-left: 0;
  font-size: 2.4rem;
}

.hero__social-icons a i:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.hero__social-icons a i {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hero__social-icons li {
  padding-left: 0;
  margin-bottom: var(--vspace-0_75);
  text-align: center;
}

.recent_work {
  padding: 2rem;
}

.work_item {
  padding: 2rem;
  border-bottom: 1px solid rgb(98, 98, 98);
}

.work_item img {
  object-fit: cover;
  width: 180px;
  height: 180px;
}

.work_item a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: var(--text-xl);
}

.work_item .text {
  max-width: 30%;
}

/*vue animation*/
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: all .3s ease-in;
  transform: translateX(100vw);
}

.fade-enter-to {
  transform: translateX(0px);

}

.fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.fade-enter,
.fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}
</style>