<template>
    <div name="cForm" id="cForm" class="s-content__form">
        <div class="form-field">
            <input v-model="name" type="text" id="cName" class="h-full-width h-remove-bottom" placeholder="Your Name">
        </div>
        <div class="form-field">
            <input v-model="email" type="text" id="cEmail" class="h-full-width h-remove-bottom" placeholder="Your Email">
        </div>
        <div class="message form-field">
            <textarea v-model="message" id="cMessage" class="h-full-width" placeholder="Your Message"></textarea>
        </div>
        <br>
        <base-button @clickEve="postComment" :disabled="btnDisabled">post comment</base-button>
    </div> <!-- end form -->
</template>

<script>
import BaseButton from "./BaseComponents/BaseButton.vue";
import post from "../../js/network.js";

export default {
    components: {
        BaseButton
    },
    data() {
        return {
            name: "",
            email: "",
            message: "",
            btnDisabled: false
        }
    },
    props: {
        postUid: String
    },
    methods: {
        postComment() {
            this.btnDisabled = true;
            // Create a new Date object
            var currentDateTime = new Date();

            // Extract the date components
            var year = currentDateTime.getFullYear();
            var month = currentDateTime.getMonth() + 1; // Month is zero-based, so add 1
            var day = currentDateTime.getDate();

            // Extract the time components
            var hours = currentDateTime.getHours();
            var minutes = currentDateTime.getMinutes();
            var seconds = currentDateTime.getSeconds();

            // Convert month, day, hours, minutes, and seconds to two-digit format if needed
            month = (month < 10 ? "0" : "") + month;
            day = (day < 10 ? "0" : "") + day;
            hours = (hours < 10 ? "0" : "") + hours;
            minutes = (minutes < 10 ? "0" : "") + minutes;
            seconds = (seconds < 10 ? "0" : "") + seconds;

            // Create the date and time string
            var dateTimeString = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;


            var commentUid = "comment-" + Math.random().toString(16).slice(2);

            var data = {
                postUid: this.postUid,
                commentId: commentUid,
                responseToId: "",
                comment: this.message,
                date: dateTimeString,
                commentBy: this.name,
                confirmed: false,
                email: this.email,
            };
            console.log(data);
            post(data, "comments")
                .then((message) => {
                    console.log(message);
                    this.btnDisabled = false;
                    this.$swal.fire(
                        {
                            position: 'top-end',
                            icon: 'info',
                            title: 'Thanks for your comment',
                            showConfirmButton: false,
                            timer: 1000
                        }
                    );

                }).catch((error) => {
                    console.log(error);
                    this.btnDisabled = false;
                    this.$swal.fire(
                        {
                            position: 'top-end',
                            icon: 'info',
                            title: 'Oops..',
                            text: 'Something went wrong, try again in a moment. If the problem persists, please contact us',
                            showConfirmButton: false,
                            timer: 1000
                        }
                    );

                });
        }

    }
}
</script>