<template>
    <div class="wrapper">
        <div class="grid-x">
            <div class="cell small-12 medium-9 large-9 posts">
                <post-gallery></post-gallery>
            </div>
            <div class="cell small-12 medium-3 large-3 sidebar">
                <div>
                    <h5>Sign Up for Newsletter</h5>
                    <h6>Signup to get updates on articles, interviews and events.</h6>
                    <div class="subscribe-form">
                        <input type="email" name="dEmail" class="email" :class="{ 'email-invalid': !emailValid }"
                            id="mc-email" placeholder="Your Email Address" required="" v-model="email">
                        <base-button @click="saveEmail" :disabled="!emailValid">Subscribe</base-button>
                        <label for="mc-email" class="subscribe-message"></label>
                    </div>
                </div>
                <div class="affiliate-disclosure">
                    <p><i>Affiliate Disclosure:</i> Please note that some of the links on this website are affiliate links.
                        This
                        means that, at no additional cost to you, we may earn a small commission if you click through and
                        make a purchase. We carefully select and promote products or services that we believe are of high
                        quality and value to our audience. Your support through these affiliate links helps us to maintain
                        and improve our website, allowing us to continue providing valuable content to our users. We
                        appreciate your trust and support!</p>
                </div>
                <div v-if="posts">
                    <recent-posts :posts="posts"></recent-posts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PostGallery from '../components/UI/PostGallery2.vue';
import RecentPosts from '../components/UI/RecentPost.vue'
import axios from "axios";
import BaseButton from "../components/UI/BaseComponents/BaseButton.vue";
import {loadJsonFromS3} from '../js/s3Service.js'

export default {
    components: {
        PostGallery,
        RecentPosts,
        BaseButton,
    },
    data() {
        return {
            posts: null,
            emailValid: false,
            email: "",
        }
    },
    created() {
        this.loadJson();
    },
    methods: {
        async loadJson() {
            try {
                // Call the loadJsonFromS3 function with the S3 URL
                var postsFromS3 = await loadJsonFromS3('https://websitebuckettom-assets.s3.eu-north-1.amazonaws.com/posts/posts.json');
                this.posts = postsFromS3
                console.log(this.posts)
            } catch (error) {
                console.error('Error loading JSON from S3:', error);
            }
        },
        saveEmail() {
            const vm = this;
            return axios.post(`https://36fve1msl3.execute-api.eu-north-1.amazonaws.com/prod/email`,
                { "email": vm.email }
            ).then((res) => {
                console.log(res);
            }).catch((e) => {
                console.error(e);
            });
        },
        validateEmail(email) {
            this.emailValid = /^[^@]+@\w+(\.\w+)+\w$/.test(email)

        },
    },
    watch: {
        email(value) {
            this.email = value;
            this.validateEmail(value);
        }
    }
}
</script>

<style scoped>
.wrapper {
    margin-top: 8.8rem;
    min-height: 100vh;
    /*margin-left: 5rem;
    margin-right: 5rem;*/
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    /*width: 1440px;*/
}

.posts {
    border-right: .5px solid black;
    min-height: 100vh;
}

.sidebar {
    padding-left: 30px;
    padding-right: 30px;
}

.right-sidebar {
    width: 30vw;
    flex: 1;
}

.affiliate-disclosure {
    font-size: var(--text-xs);
    border-bottom: .5px solid black;
    border-top: .5px solid black;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.subscribe-form input[type="email"] {
    --input-height: vspace(1.75);
    background-color: transparent;
    border: 1px solid var(--color-text);
    padding: .8rem 2rem;
    width: 100%;
    text-align: center;
    margin-bottom: var(--vspace-0_5);
    --input-line-height: var(--vspace-1);
    --input-vpadding: calc((var(--input-height) - var(--input-line-height)) / 2);
    display: block;
    height: var(--input-height);
}

.subscribe-form input[type="email"].email-invalid {
    border: 1px solid #5d1111;
}

.btn,
button,
input[type="submit"].email-invalid-btn {
    opacity: 0.5;
    color: rgb(255, 255, 255);
    cursor: not-allowed;
}

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    --btn-height: var(--vspace-btn);
    display: inline-block;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: var(--text-xs);
    text-transform: uppercase;
    letter-spacing: .4em;
    height: var(--btn-height);
    line-height: calc(var(--btn-height) - .4rem);
    padding: 0 3.2rem;
    margin: 0 .4rem 1.6rem 0;
    color: var(--color-btn-text);
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-btn);
    border: 0.2rem solid var(--color-btn);
    width: 100%;
}
</style>