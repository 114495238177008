<template>
    <div class="comments-wrap">
        <div id="comments" class="row">
            <div class="column large-12">
                <base-content-card :collapsed="true">
                    <template #title>
                        <img src="../../assets/comment_icon.png" class="comment_logo" alt="comment" />
                        <span class="comment-count">{{ commentCount }}</span>
                    </template>
                    <template #actions>
                        <ol class="commentlist">
                            <li v-for="comment in comments" :key="comment.commentId" class="depth-1 comment">
                                <div class="comment__avatar">
                                    <img class="avatar" src="../../assets/user-01.png" alt="" width="50" height="50">
                                </div>
                                <div class="comment__content">
                                    <div class="comment__info">
                                        <div class="comment__author">{{ comment.commentBy }}</div>
                                        <div class="comment__meta">
                                            <div class="comment__time">{{ comment.date }}</div>
                                            <div class="comment__reply">
                                                <a class="comment-reply-link" href="#0">Reply</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comment__text">
                                        <p>{{ comment.comment }}</p>
                                    </div>
                                </div>
                                <ul class="children">
                                    <li v-for="child in getChildsFor(comment.commentId)" :key="child.commentId"
                                        class="depth-2 comment">
                                        <div class="comment__avatar">
                                            <img class="avatar" src="../../assets/user-01.png" alt="" width="50"
                                                height="50">
                                        </div>
                                        <div class="comment__content">
                                            <div class="comment__info">
                                                <div class="comment__author">{{ child.commentBy }}</div>
                                                <div class="comment__meta">
                                                    <div class="comment__time">{{ child.date }}</div>
                                                    <div class="comment__reply">
                                                        <a class="comment-reply-link" href="#0">Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="comment__text">
                                                <p>{{ child.comment }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <comment-input :postUid="postUid"></comment-input>
                    </template>
                </base-content-card>
            </div>
        </div>
    </div>
</template>

<script>
import BaseContentCard from './BaseComponents/BaseContentCard.vue';
import CommentInput from '../UI/CommentInput.vue';
import commentsData from '../../assets/comments.json';
import post from '../../js/network.js';

export default {
    components: {
        BaseContentCard,
        CommentInput
    },
    data() {
        return {
            comments: [],
            commentCount: 0
        }
    },
    props: {
        postUid: String
    },
    methods: {
        loadComments() {

            var data = {
                postUid: this.postUid,
                commentId: "",
                responseToId: "",
                comment: this.message,
                date: "",
                commentBy: "",
                confirmed: false,
                email: "",
                updateComments: true
            };

            post(data, 'comments')
                .then((message) => {
                    this.comments = JSON.parse(message.body);
                    this.commentCount = this.comments.length;
                })
                .catch((error) => { console.error(error) })


            //this.comments = commentsData.comments.filter(comment => comment.postUid === this.postUid && comment['responseToId'] === "");
        },
        getChildsFor(id) {
            return commentsData.comments.filter(comment => comment.responseToId === id && comment.postUid === this.postUid);
        }
    },
    mounted() {
        this.loadComments();
    }

}
</script>

<style scoped>
.comment_logo {
    max-width: 50px;
}

.comment-count {
    position: absolute;
    /* background-color: var(--color-text-light); */
    border-radius: 100%;
    color: var(--color-text-light);
    left: 18px;
}

.comments-wrap {
    padding-bottom: var(--vspace-4);
}

.comments-wrap h3 {
    text-align: center;
    margin-top: 0;
    margin-bottom: var(--vspace-0_5);
}

.comments-wrap h3 span {
    display: block;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: calc(var(--text-size) * 0.8889);
    line-height: var(--vspace-0_75);
    color: var(--color-text-light);
    margin-top: calc(0.125 * var(--space));
}

/* comments
 * -------------------------------------------------- */
#comments {
    padding-top: var(--vspace-0_5);
    padding-bottom: var(--vspace-0_5);
    max-width: 980px;
}

#comments ol,
#comments ul {
    list-style: none;
}

.commentlist {
    margin: var(--vspace-2) 0 var(--vspace-1_5);
    padding: 0;
}

.commentlist>.comment {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 2rem 0 0 0;
    padding-left: 14%;
}

.comment__avatar {
    position: absolute;
    left: 0;
    display: block;
}

.comment__avatar img {
    height: var(--vspace-2);
    width: var(--vspace-2);
    border-radius: 100%;
    vertical-align: bottom;
}

.comment__info {
    position: relative;
}

.comment__info .comment__author {
    font-weight: 600;
    font-size: var(--text-size);
    line-height: var(--vspace-1);
    font-style: normal;
    color: black;
}

.comment__meta {
    font-style: normal;
    line-height: var(--vspace-0_75);
    display: block;
    color: var(--color-text-light);
    text-align: right;
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;
}

.comment__meta .comment__time {
    font-size: calc(var(--text-size) * 0.7778);
    text-transform: uppercase;
    letter-spacing: .1rem;
    color: var(--color-text-light);
}

.comment__meta .comment__reply a {
    font-size: var(--text-xs);
    font-family: var(--font-1);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;
    color: black;
}

.comment__text {
    clear: both;
    margin: var(--vspace-0_5) 0 0 0;
    padding: 0 175px 0 0;
}

.comment ul.children {
    margin: 0;
    padding: 0;
}

.comment ul.children li {
    padding-left: 5%;
    margin-top: calc(var(--vspace-1) + 2rem);
    border-left: 1px solid var(--color-border);
}

/* comments form
 * ---------------------------- */
.comments-wrap .comment-respond {
    max-width: 900px;
    margin-top: var(--vspace-2);
}

.comment-respond form {
    padding-top: var(--vspace-1_75);
}

/* ------------------------------------------------------------------- 
 * responsive:
 * comments
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {

    #comments,
    .comments-wrap .comment-respond {
        max-width: none;
        padding-right: 5vw;
        padding-left: 5vw;
    }
}

@media screen and (max-width: 1000px) {

    #comments,
    .comments-wrap .comment-respond {
        padding-right: 0;
        padding-left: 0;
    }

    .comment__avatar img {
        height: var(--vspace-1_75);
        width: var(--vspace-1_75);
    }

    .comment__meta {
        text-align: left;
        width: auto;
        position: static;
    }

    .comment__meta .comment__time,
    .comment__meta .comment__reply {
        display: inline-block;
    }

    .comment__meta .comment__time {
        margin-right: 12px;
    }

    .comment__text {
        padding: 0;
    }

    .comments-wrap .comment-respond {
        margin-top: var(--vspace-1_5);
    }
}

@media screen and (max-width: 800px) {
    .comments-wrap {
        padding-bottom: var(--vspace-3);
    }

    .comment__avatar img {
        margin-top: 0;
        height: var(--vspace-1_5);
        width: var(--vspace-1_5);
    }
}

@media screen and (max-width: 600px) {

    #comments,
    .comments-wrap .comment-respond {
        padding-right: 6vw;
        padding-left: 6vw;
    }

    .commentlist>.comment {
        padding-top: 0;
        padding-left: 0;
    }

    .comment__avatar {
        display: none;
    }

    .comment ul.children li {
        margin-top: calc(var(--vspace-1) + 0rem);
    }
}

@media screen and (max-width: 500px) {
    .comment ul.children li {
        border-left: none;
    }
}

@media screen and (max-width: 400px) {
    .comment-respond form {
        padding-top: var(--vspace-1);
    }
}
</style>