<template>
    <section id="hero" class="hero">
        <!-- <img src="@/assets/heroImg1.jpg" alt="" class="heroimg"> -->
        <!-- <div class="hero__content" :style="{ backgroundImage: 'url(' + require('@/assets/heroImg4.jpg') + ')' }"></div> -->
        <div class="hero__content" :style="{ backgroundImage: 'url(' + getHeroImage() + ')' }">
            <div class="hero__content-bg"></div>
            <div class="grid-x hero__text-content">
                <transition name="slide" appear>
                    <div v-if="animate" class="cell large-8">
                        <div class="hero__links hide-on-mobile">
                            <!-- <span class="cat-links">
                                <router-link :to="'/blog'">Blog</router-link>
                                <router-link :to="'/'">Portfolio</router-link>
                            </span> -->
                        </div>
                        <h1 class="hero__main-text">
                            <router-link :to="'/about'"> {{ getHeaderText() }} </router-link>
                        </h1>
                    </div>
                </transition>
            </div>
        </div>

        <div class="hero__social hide-on-mobile">
            <p>Follow</p>
            <span></span>
            <ul class="hero__social-icons">
                <li><a href="https://www.linkedin.com/in/tom-andersson-4a7189113/" aria-label="Follow on linkedin"
                        target=”_blank”><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/lifearray/" aria-label="Follow on instagram" target=”_blank”><i
                            class="fab fa-instagram" aria-hidden="true"></i></a></li>
            </ul>
        </div>

    </section>
    <!-- <section>
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell large-6">
                    <p class="lead montserrat-lead">As a passionate and skilled software developer, I am driven by a strong
                        desire
                        to create systems
                        that make
                        people's lives easier, more efficient, and more enjoyable. Whether it's in the realm of education,
                        leadership,
                        or everyday tasks, I am committed to using my extensive knowledge and expertise to bring innovative
                        ideas to
                        life.</p>
                    <router-link class="montserrat-link" :to="'/about'">Read more</router-link>

                </div>
                <div class="cell large-6">
                    <p class="lead montserrat-lead">As a passionate and skilled software developer, I am driven by a strong
                        desire
                        to create systems
                        that make
                        people's lives easier, more efficient, and more enjoyable. Whether it's in the realm of education,
                        leadership,
                        or everyday tasks, I am committed to using my extensive knowledge and expertise to bring innovative
                        ideas to
                        life.</p>
                    <router-link class="montserrat-link" :to="'/about'">Read more</router-link>

                </div>
            </div>
        </div>
        <div class="hero__short__desc">
            <p class="lead montserrat-lead">As a passionate and skilled software developer, I am driven by a strong desire
                to create systems
                that make
                people's lives easier, more efficient, and more enjoyable. Whether it's in the realm of education,
                leadership,
                or everyday tasks, I am committed to using my extensive knowledge and expertise to bring innovative ideas to
                life.</p>
            <router-link class="montserrat-link" :to="'/about'">Read more</router-link>
        </div>
    </section> -->
</template>

<script>
export default {
    data() {
        return {
            animate: false,
        }
    },
    methods: {
        getHeaderText() {
            return "Tom Andersson - " + this.$store.getters.getContentDescription;
        },
        getHeroImage() {
            return require(`@/assets/hero/${this.$store.getters.getHeroImageName}`);
        }
    },
    mounted() {
        this.animate = false
        setTimeout(() => {
            this.animate = true
        }, 200)
    },
}
</script>


<style scoped lang="scss">
/* ------------------------------------------------------------------- 
 * ## medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (min-width: 1201px) {
    .hero__main-text {
        font-size: 8.8rem;
    }
}

/* ------------------------------------------------------------------- 
 * ## medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {
    .hero__main-text {
        font-size: 8.8rem;
    }
}

/* ------------------------------------------------------------------- 
 * ## tablets
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
    .hero__main-text {
        font-size: 5.8rem;
    }
}

/* ------------------------------------------------------------------- 
 * ## mobile devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {
    .hero__main-text {
        font-size: 4.8rem;
    }
}

/* ------------------------------------------------------------------- 
 * ## small mobile devices <= 400px
 * ------------------------------------------------------------------- */
@media screen and (max-width: 400px) {
    .hero__main-text {
        font-size: 1.8rem;
    }
}

.hero {
    width: 100%;
    height: 100vh;
    min-height: calc(25.5 * var(--space));
    background-color: #151515;
    // overflow: hidden;
    position: relative;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
}

.hero__content {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/heroImg5.jpg'); */
}

.hero__text-content {
    height: 100%;
    display: flex;
    align-content: center;
    padding-left: 5rem;
}

.hero__main-text {
    font-family: var(--font-3);
    /*font-size: 8.8rem;*/
    font-weight: 400;
    line-height: 1.091;
    letter-spacing: -.01em;
    color: white;
    margin-top: 0;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.hero__main-text a {
    color: white;
}

.hero__links span a {
    color: var(--color-gray-2);
    margin-right: 3rem;
    font-size: var(--text-size);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hero__links span a:hover {
    font-size: 2rem;
}

/********* Slide Animation ************/
.slide-enter {
    opacity: 0;
}

.slide-enter-active {
    animation: slide-in 1s ease-in forwards;
    transition: opacity 2.5s;
}

.slide-leave {
    /* initial state set in keyframe*/
}

.slide-leave-active {
    animation: slide-out 1s ease-out forwards;
    opacity: 0;
    transition: opacity 10s;
}


@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(20px);
    }
}

.animate-this {
    opacity: 0;
    visibility: hidden;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}

@keyframes fadInUp {

    0% {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    100% {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.hero__social {
    font-size: var(--text-xs);
    font-weight: 400;
    color: white;
    text-align: right;
    position: absolute;
    right: 4rem;
    bottom: 25vh;
}

.hero__social a {
    color: white;
}

.hero__social span {
    display: block;
    width: 1px;
    height: 3.6rem;
    background-color: white;
    margin: 3.6rem auto 4.8rem;
}

.hero__social p {
    line-height: 1;
    margin: 0;
    position: relative;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.hero__social-icons {
    list-style: none;
    margin-left: 0;
    font-size: 2.4rem;
}

.hero__social-icons a i:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.hero__social-icons a i {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hero__social-icons li {
    padding-left: 0;
    margin-bottom: var(--vspace-0_75);
    text-align: center;
}

.hero__short__desc {
    // position: absolute;
    // top: 70%;
    // width: 80%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border-radius: 25px;
    // backdrop-filter: blur(5px);
    // background: rgba(255, 255, 255, 0.1);
    // box-shadow: 0 25px 45px rgba(0, 0, 0, 0.25);
    // border: 1px solid rgba(255, 255, 255, 0.1);
    backface-visibility: hidden;
    color: black;

    a {
        color: black;
    }
}
</style>