import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
//import VueI18n from 'vue-i18n'
//createWebHistory

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import store from "./store/index.js";



import App from "./App.vue";
import HomePage from "./Pages/HomePage.vue";
import PostPage from "./Pages/PostPage.vue";
import CategoryPage from "./Pages/CategoryPage.vue";
import RegisterAdmin from "./components/RegisterAdmin.vue";
import ConfirmSignup from "./components/ConfirmSignup.vue";
import LoginPage from "./Pages/LoginPage.vue";
import ContactPage from "./Pages/ContactPage.vue";
import AboutPage from "./Pages/AboutPage.vue";
import BlogPage from "./Pages/BlogPage.vue";
import PortfolioPage from "./Pages/PortfolioPage.vue"
import GoalPage from "./Pages/GoalsPage.vue"


const routes = [
  { path: "/", component: HomePage },
  { path: "/:type", component: HomePage },
  { path: "/register", component: RegisterAdmin },
  { path: "/confirm", component: ConfirmSignup },
  { path: "/post/:id", component: PostPage },
  { path: "/login", component: LoginPage },
  { path: "/blog", component: BlogPage },
  { path: "/portfolio", component: PortfolioPage },
  { path: "/about", component: AboutPage},
  { path: "/contact", component: ContactPage },
  { path: "/cat/:id", component: CategoryPage },
  { path: "/getthere", component: GoalPage },

];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }// return desired position
  }
});
///?ct=your_content_type_here
router.beforeEach((to, from, next) => {
  // Check if the user is navigating to the root path with a content type parameter
  if (to.path === '/' && to.query.ct) {
    // Set the content type in the store
    store.commit('setContentType', to.query.ct);
  }

  // Continue to the next route
  next();
});


const app = createApp(App);

//https://www.google.com/search?q=build+own+vuw+3+plugins&rlz=1C5CHFA_enSE1042SE1042&sxsrf=AJOqlzXXiCvYPgF5o2hXhPAKalD-7aSAhA%3A1678455419189&ei=ezILZPCUC5L1qwGlupHYAg&oq=build+own+vuw+3+plugin&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAxgAMgcIIRCgARAKOgoIABBHENYEELADOgQIIxAnOgYIIxAnEBM6DgguEIAEELEDEMcBENEDOgsILhCABBDHARDRAzoFCAAQgAQ6CAgAEIAEELEDOggILhCABBCxAzoLCAAQgAQQsQMQgwE6BAguECc6CwguEIAEEMcBEK8BOg4ILhCvARDHARCxAxCABDoOCC4QsQMQgwEQxwEQ0QM6CwguEIAEELEDEIMBOhQILhCABBCxAxCDARDJAxDHARDRAzoECAAQQzoHCAAQgAQQCjoFCC4QgAQ6CwguEIAEENQCEMsBOggIABCABBDLAToGCAAQFhAeOggIABAWEB4QCjoICAAQogQQiwM6BAghEBVKBAhBGABQ-Q5Y2Hdg3IMBaANwAXgAgAFyiAG-EJIBBDEzLjmYAQCgAQHIAQW4AQLAAQE&sclient=gws-wiz-serp#fpstate=ive&vld=cid:10ddad57,vid:ar1fJECxbyU
//need to add a real vue plugin with the methods, look at video above
app.use(store)

const options = {
  confirmButtonColor: "#d0d1d1",
  cancelButtonColor: "#d1d0d0",
};

app.use(router);
app.use(VueSweetalert2, options);
app.mount("#app");
