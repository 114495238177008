<template>
    <div class="goals-container">
        <h1>Get there</h1>
        <ul>
            <li>Read 10 Books</li>
            <li>Scrum Pal 1 & Scrum Master</li>
            <li>Aws developer cert</li>
            <li>200 k N</li>
            <li>200 k A</li>
            <li>Second income stream</li>
            <!-- Add more goals as needed -->
        </ul>
    </div>
</template>

<script>


export default {

}
</script>

<style scoped>
header {
    display: none !important;
}

footer {
    display: none !important;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.goals-container {
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    min-width: 100vw;
}

h1 {
    color: #333;
    text-align: center;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}
</style>