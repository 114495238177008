export async function loadJsonFromS3(url) {
  try {
    // Fetch the JSON file from S3 using its URL

    // const response = await fetch(url, {
    //   method: "GET",
    //   mode: "no-cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    const response = await fetch(url);
    // Check if the request was successful
    if (response.ok) {
      // Parse the JSON response and return
      return await response.json();
    } else {
      console.log(response)
      throw new Error(`Failed to fetch JSON from S3: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error loading JSON from S3:", error);
    throw error; // Rethrow the error for handling in the Vue component
  }
}

// Function to load HTML from S3 URL
export async function loadHtmlFromS3(url) {
  try {
    // Fetch the HTML content from S3 using its URL
    const response = await fetch(url);
    // Check if the request was successful
    if (response.ok) {
      // Return the HTML content
      return await response.text();
    } else {
      throw new Error(`Failed to fetch HTML from S3: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error loading HTML from S3:", error);
    throw error; // Rethrow the error for handling in the Vue component
  }
}
