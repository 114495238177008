<template>
    <div class="pagination-container">
        <!-- <button @click="prevPage" :disabled="currentPage === 1">Previous</button> -->

        <ul class="pagination">
            <li>
                <span @click="prevPage" :disabled="currentPage === 1">Prev</span>
            </li>
            <li v-for="index in totalPages" :key="index" class="number">
                <span :class="currentPage == index ? 'current' : ''" @click="goToPage(index)">
                    {{ index }}
                </span>
            </li>
            <li>
                <span @click="nextPage" :disabled="currentPage === totalPages">Next</span>
            </li>
        </ul>
        <!-- <button @click="nextPage" :disabled="currentPage === totalPages">Next</button> -->
    </div>
</template>
  
<script>
export default {
    props: {
        currentPage: Number,
        totalPages: Number,
    },
    methods: {
        prevPage() {
            if (this.currentPage > 1) {
                this.$emit('page-change', this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('page-change', this.currentPage + 1);
            }
        },
        goToPage(page) {
            if (this.currentPage != page) {
                this.$emit('page-change', page);
            }
        }
    },
};
</script>
  
<style scoped>
* {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.pagination-container {
    /*position: relative;*/
    /*height: 100vh;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://images.pexels.com/photos/247431/pexels-photo-247431.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  background-position: center;*/
    padding: 2rem;
    justify-content: center;
}

.pagination-container .pagination {
    position: relative;
    height: 60px;
    /* background: rgba(255, 255, 255, 0.05);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.03); */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    border-radius: 2px;
}

.pagination-container .pagination li {
    list-style-type: none;
    display: inline-block;
}

/* @media screen and (max-width: 400px) {
    .pagination-container .pagination li.number {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .pagination-container .pagination li.number {
        display: none;
    }
} */

.pagination-container .pagination li span {
    position: relative;
    padding: 20px 35px;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 1.4rem;
}

.pagination-container .pagination span.current {
    background: rgba(124, 124, 124, 0.125);
}

.pagination-container .pagination li span:hover,
.pagination-container .pagination li.active span {
    background: rgba(255, 255, 255, 0.466);
}
</style>
  