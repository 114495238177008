<template>
    <button :class="[
        'normal',
        { 'invalid': disabled },
        { 'cta': type === 'cta' },
        { 'light': type === 'light' },
        { 'secondary': type === 'secondary' }
    ]" :disabled="disabled" @click="callback($event)">
        <slot></slot>
    </button>
</template>
  
<script>
export default {
    data() {
        return {
            valid: false
        };
    },
    props: {
        type: String,
        disabled: Boolean
    },
    methods: {
        callback: function (e) {
            this.$emit('clickEve', e);
        }
    }
};
</script>
  
<style scoped>
button {
    --btn-height: var(--vspace-btn);
    display: inline-block;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: var(--text-xs);
    text-transform: uppercase;
    letter-spacing: .4em;
    height: var(--btn-height);
    line-height: calc(var(--btn-height) - .4rem);
    padding: 0 3.2rem;
    margin: 0 .4rem 1.6rem 0;
    color: var(--color-btn-text);
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button.invalid {
    opacity: 0.5;
    color: rgb(255, 255, 255);
    cursor: not-allowed;
}

button.cta {
    /* Add styles for the primary button type */
    background-color: var(--color-gray-19);
    color: var(--color-gray-2);
    /* border: 0.2rem solid var(--primary-color); */
}

button.cta:hover {
    /* Add styles for the primary button type */
    background-color: var(--color-gray-6);
    color: var(--color-gray-19);
    /* border: 0.2rem solid var(--primary-color); */
}

button.light {
    /* Add styles for the primary button type */
    background-color: var(--color-gray-6);
    color: var(--color-gray-19);
    /* border: 0.2rem solid var(--primary-color); */
}

button.light:hover {
    /* Add styles for the primary button type */
    background-color: var(--color-gray-19);
    color: var(--color-gray-6);
    /* border: 0.2rem solid var(--primary-color); */
}
button.secondary {
    /* Add styles for the secondary button type */
    background-color: var(--secondary-color);
    border: 0.2rem solid var(--secondary-color);
}
</style>
  