<template>
    <article v-if="!isSmall" class="brick entry">
        <div class="entry__thumb">
            <router-link class="thumb-link" :to="'/post/' + post.id">
                <img :src="getImgUrl" width="256" height="256" :alt="post.title">
            </router-link>

        </div> <!-- end entry__thumb -->

        <div class="entry__text">
            <div class="entry__header">
                <h1 class="entry__title">
                    <router-link class="thumb-link" :to="'/post/' + post.id">
                        {{ post.title }}
                    </router-link>
                </h1>

                <div class="entry__meta">
                    <span class="byline hide-on-mobile">By:
                        <span class=" author">
                            <a href="">{{ post.author.name }}</a>
                        </span>
                    </span>
                    <span class="cat-links"> in:
                        <router-link :to="'/cat/' + cat" v-for="(cat, index) in post.categories" :key="index"
                            rel="category">
                            {{ cat }}
                        </router-link>
                    </span>
                    <span class="byline"> Updated:
                        <time class="meta-mod-date updated" datetime-local="">{{ getPostDate(post) }}
                        </time>
                    </span>
                </div>
            </div>
            <div class="entry__excerpt">
                <p>
                    <!--Add short description-->
                </p>
            </div>
            <!-- <router-link class="thumb-link" :to="'/post/' + post.uid">
                Learn More</router-link> -->
        </div> <!-- end entry__text -->
        <span class="hide-on-mobile divider"></span>
    </article>
    <article v-else>
        <div class="brick-small">
            <div class="entry__thumb_small">
                <router-link class="thumb-link_small" :to="'/post/' + post.id">
                    <img class="img_small" :src="getImgUrl" alt="">
                </router-link>

            </div> <!-- end entry__thumb -->
            <div class="entry__text_small">
                <div class="entry__header">
                    <h1 class="entry__title_small">
                        <router-link class="thumb-link" :to="'/post/' + post.id">
                            {{ post.title }}
                        </router-link>
                    </h1>

                    <div class="entry__meta">
                        <span class="sidebar-text-small"> in:
                            <router-link :to="'/cat/' + cat" v-for="(cat, index) in post.categories" :key="index"
                                rel="category">
                                {{ cat }}
                            </router-link>
                        </span>
                        <span class="sidebar-text-small"> Updated:
                            <time class="meta-mod-date updated" datetime-local="">{{ getPostDate(post) }}
                            </time>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        post: {},
        isSmall: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getPostDate(post) {
            let postdate = "";
            if (post.date) {
                postdate = post.date;
            }

            return postdate;
        }
    },
    computed: {
        getImgUrl() {
            if (this.post.coverImage) {
                return this.post.coverImage.url;
            }
            return require(`@/assets/img/altPostImg.jpg`);
        }
    }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 1201px) {
    .brick {
        flex: 1 0 21%;
        max-width: 25%;
        padding: 0rem 4rem 0 4rem;
        margin-top: 5rem;
        position: relative;
        min-height: 270px;
    }
}

/* ------------------------------------------------------------------- 
 * ## medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {
    .brick {
        flex: 1 0 48%;
        max-width: 50%;
        padding: 0rem 4rem 0 4rem;
        margin-top: 5rem;
        position: relative;
    }
}

/* ------------------------------------------------------------------- 
 * ## tablets
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {}

/* ------------------------------------------------------------------- 
 * ## mobile devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {
    .brick {
        display: flex;
        flex: 1 0 98%;
        max-width: 100%;
        padding: 2rem 4rem 2rem 4rem;
        // margin-top: 5rem;
        position: relative;
        border-bottom: 1px solid var(--color-gray-2);

        .entry__thumb {
            max-width: 30%;
        }

        .entry__title {
            font-size: var(--text-size);
        }

        .entry__text {
            padding-left: 2rem;
        }

        .entry__thumb {
            height: auto;
            min-width: 105px;
        }

        img {
            object-fit: cover;
            width: 105px;
            height: 105px;
        }
    }
}

/* ------------------------------------------------------------------- 
 * ## small mobile devices <= 400px
 * ------------------------------------------------------------------- */
@media screen and (max-width: 400px) {}

.brick-small {
    display: flex;
}

.entry__thumb_small {
    max-width: 30%;
}

.entry__title_small {
    font-size: var(--text-sm);
}

.entry__text_small {
    padding-left: 2rem;
}

.entry__meta a {
    color: var(--color-text-light);
}

.entry__meta time {
    color: var(--color-text-light);
}

.entry__title,
.entry__title_small a {
    color: black;
}

.sidebar-text-small {
    font-size: var(--text-xs);
}

.img_small {
    object-fit: cover;
    width: 55px;
    height: 55px;
}

.entry__title {
    font-size: var(--text-md);
    font-weight: 400;
    line-height: calc(var(--vspace-1) + var(--vspace-0_125));
    margin-top: 0;
    margin-bottom: calc(0.375 * var(--space));
    color: black;
}

.entry__meta {
    font-family: var(--font-1);
    font-weight: 400;
    font-size: calc(var(--text-size) * 0.7778);
    line-height: var(--vspace-0_75);
    margin-bottom: var(--vspace-0_75);
    display: flex;
    flex-direction: column;
}

.entry__meta .cat-links a::after {
    content: ", ";
}

.entry__meta .cat-links a:last-child::after {
    display: none;
}

.entry__meta .byline {
    margin-right: .8rem;
}

.sidebar-text-small {}

.brick:nth-child(4) .divider {
    display: none;
}

.divider {
    height: 100%;
    width: 1px;
    background-color: var(--color-gray-2);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.entry__thumb {
    height: 30vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>