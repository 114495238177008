<template>
    <div class="wrapper">
        <div class="cat-header">
            <h4 class="cat-identifier" v-for="(cat, index) in categories" :key="index">{{ cat }}</h4>
        </div>
        <post-gallery v-if="categories" :showCategoryFilter="false" :filter="categories.join(',')"></post-gallery>
    </div>
</template>

<script>
import PostGallery from '../components/UI/PostGallery2.vue'

export default {
    components: {
        PostGallery
    },
    data() {
        return {
            categories: null
        };
    },
    created() {
        this.updateCategories();
    },
    watch: {
        $route: 'updateCategories'
    },
    methods: {
        updateCategories() {
            const categoryId = this.$route.params.id;
            this.categories = categoryId ? categoryId.split(',') : [];
        }
    }
};
</script>

<style scoped>
.wrapper {
    padding-top: 10rem;
}

.cat-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cat-idedentifier::after {
    content: ",";
}

.cat-idedentifier:last-child::after {
    display: none;
}
</style>