<template>
    <div>
        <img v-if="getImgUrl" width="2560" height="1707" :src="getImgUrl" class="full-size-image" alt="Cover Img"
            decoding="async" loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px">
        <div class="container">
            <header class="blog-post-title">
                <h1 class="single-post-title">{{ post.title }}</h1>
                <div class="blog-post-author">
                    <div class="blog-post-author-content">
                        <div class="post-author-name">Written by
                            <a class="lightgrey-text-link" href="https://tomandersson.se/?author=2" rel="author">
                                {{ post.author.name }}
                            </a>
                        </div>
                        <div class="post-author-description"></div>
                    </div>
                </div><!-- .blog-post-author -->
                <ul class="meta-item sub-menu">
                    <li class="sub-menu-item meta-mod-date">Updated
                        <time class="meta-mod-date updated" :datetime="post.date">{{ post.date }}</time>
                    </li>
                    <li class="sub-menu-item meta-cat">Posted in
                        <router-link class="lightgrey-text-link" :to="'/cat/' + cat" v-for="(cat, index) in post.categories"
                            :key="index" rel="category">
                            {{ cat }}
                            <span v-if="index !== post.categories.length - 1">,</span>
                        </router-link>
                    </li>
                    <li class="sub-menu-item meta-rt">{{ readTime }} mins read</li>
                </ul>
            </header>
            <div class="grid-container post-content">
                <div class="grid-x grid-margin-x">
                    <div class="cell large-12" v-html="htmlContent"></div>
                    <!-- <div class="cell large-12">
                        <comments-list :postid="postid"></comments-list>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line
import CommentsList from '../components/UI/CommentsList.vue';
import { loadHtmlFromS3, loadJsonFromS3 } from '../js/s3Service.js';
import metaMixins from '../js/metaMixin.js'

export default {
    // eslint-disable-next-line
    components: { CommentsList },
    data() {
        return {
            postid: null,
            post: {},
            fullPost: {},
            readTime: Number,
            htmlContent: '',
            imgUrl: null
        }
    },
    methods: {
        async loadJson() {
            try {
                var postsFromS3 = await loadJsonFromS3('https://websitebuckettom-assets.s3.eu-north-1.amazonaws.com/posts/posts.json');
                let vm = this
                var post = postsFromS3.filter(function (item) { return item.id === vm.postid; });
                this.post = post[0];
            } catch (error) {
                console.error('Error loading JSON from S3:', error);
            }
        },
        loadPost(route) {
            this.postid = route;
            this.loadJson();
        },
        readingTime(text) {
            const wpm = 300;
            const words = text.trim().split(/\s+/).length;
            const time = Math.ceil(words / wpm);
            this.readTime = time;
        },
        async fetchHtmlContent() {
            try {
                var object = await loadHtmlFromS3("https://websitebuckettom-assets.s3.eu-north-1.amazonaws.com/posts/" + this.postid + '/' + this.postid + '.json');
                object = JSON.parse(object)
                this.htmlContent = object.content.html;
                this.fullPost = object;
                this.readingTime(this.htmlContent);

                metaMixins.updateMetaTags(
                    this.post.title,
                    this.generateDescription(),
                    this.generateKeywords()
                );

            } catch (error) {
                console.error('Error loading HTML from S3:', error);
            }
        },

        generateKeywords() {
            if (this.post && this.post.categories) {
                return this.post.categories.join(', ');
            }
            return '';
        },
        generateDescription() {
            if (this.fullPost && this.fullPost.seoOverride) {
                return this.fullPost.seoOverride.title == "Description" ?
                    this.fullPost.seoOverride.description :
                    `Read about ${this.post.title} written by ${this.post.author.name}.`
            }
            if (this.post && this.post.title) {
                return `Read about ${this.post.title} written by ${this.post.author.name}.`;
            }
            return 'Read our latest blog posts.';
        }
    },
    computed: {
        getImgUrl() {
            if (this.post.coverImage) {
                return this.post.coverImage.url;
            }
            return require(`@/assets/img/altPostImg.jpg`);
        },
    },
    created() {
        this.loadPost(this.$route.params.id);
    },
    watch: {
        $route(newRoute) {
            this.loadPost(newRoute.params.id);
        }
    },
    mounted() {
        this.fetchHtmlContent();
    }
}
</script>

<style>
table.properties {
    display: none;
}

figcaption {
    font-size: 9px;
}

.lightgrey-text-link {
    color: var(--color-gray-5);
}

.container {
    top: -130px;
    position: relative;
}

.post-content {
    padding-top: 20px;
}

.post-content header {
    display: none;
}

.blog-post-title {
    background-color: var(--color-gray-17);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    padding-top: 4rem;
    padding-right: 4rem;
    padding-left: 4rem;
    padding-bottom: 2rem;
}

.blog-post-author-content {
    color: var(--color-gray-2);
}

.single-post-title {
    color: var(--color-gray-2);
    margin-bottom: 2rem;
}

.meta-item {
    color: var(--color-gray-2);
}

.sub-menu {
    list-style: none;
    display: flex;
    margin: 0px;
}

.sub-menu li {
    font-size: var(--text-xs);

}

.sub-menu-item {
    border-right: 1px solid #929393;
    margin-right: 15px;
    padding-right: 15px;
}

.full-size-image {
    max-height: 700px;
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
    max-width: none;
    max-height: 35vh;
    /* position: absolute; */
}

.blog-post-author-content {
    margin-bottom: 2rem;
}
</style>