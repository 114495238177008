<template>
    <footer class="footer">
        <div class="s-footer__main">
            <div class="grid-x">
                <div class="cell medium-6 large-4 small-12 section">
                    <h5>About This Site</h5>
                    <p>
                        Here you will find a collection of Tom Andersson's thoughts, experiences, and creative projects.
                        From insightful blog posts to captivating portfolio showcases, this site serves as a platform for
                        Tom to share their journey and passions with you. Explore, connect, and get to know Tom better
                        through the various content available. Thank you for visiting!
                    </p>
                </div> <!-- end s-footer__info -->
                <div class="cell medium-6 large-2 small-12 footer_list section">
                    <h5>Site Links</h5>
                    <ul>
                        <li>
                            <router-link :to="'/about'">About</router-link>
                        </li>
                        <li>
                            <router-link :to="'/blog'">Blog</router-link>
                        </li>
                        <li>
                            <router-link :to="'/contact'">Contact</router-link>
                        </li>
                    </ul>
                </div>

                <div class="cell medium-6 large-2 small-12 footer_list section">
                    <h5>Follow Us</h5>
                    <ul>
                        <li><a href="https://www.linkedin.com/in/tom-andersson-4a7189113/" target=”_blank”>LinkedIn</a></li>
                        <li><a href="https://www.instagram.com/lifearray/" target=”_blank”>Instagram</a></li>
                    </ul>
                </div>
                <div class="cell medium-6 large-4 small-12 section">
                    <h5>Sign Up for Newsletter</h5>

                    <p>Signup to get updates on articles, interviews and events.</p>

                    <div class="subscribe-form">
                        <input type="email" name="dEmail" class="email" :class="{ 'email-invalid': !emailValid }"
                            id="mc-email" placeholder="Your Email Address" required="" v-model="email">
                        <label for="mc-email" class="subscribe-message"></label>

                        <base-button @clickEve="saveEmail" :disabled="!emailValid">Subscribe</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-footer__bottom">
            <div class="ss-go-top">
                <a class="smoothscroll" title="Back to Top" href="#top">
                    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                        <path
                            d="M7.5 1.5l.354-.354L7.5.793l-.354.353.354.354zm-.354.354l4 4 .708-.708-4-4-.708.708zm0-.708l-4 4 .708.708 4-4-.708-.708zM7 1.5V14h1V1.5H7z"
                            fill="currentColor"></path>
                    </svg>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
// import axios from "axios";
import BaseButton from "../BaseComponents/BaseButton.vue"
import post from "../../../js/network.js"
export default {
    components: { BaseButton },
    data() {
        return {
            email: "",
            emailValid: false
        }
    },
    methods: {
        saveEmail() {
            var data = {
                name: "subscribe",
                phone: "",
                email: this.email,
                desc: "new subscriber"
            };
            post(data, "subscribeemail")
                .then(() => {
                    this.$swal.fire(
                        {
                            position: 'top-end',
                            icon: 'info',
                            title: 'Thanks for subscribing, keep an eye out in your inbox',
                            showConfirmButton: false,
                            timer: 1500
                        }
                    );
                }).catch(() => {
                    this.$swal.fire(
                        {
                            position: 'top-end',
                            icon: 'info',
                            title: 'Oops..',
                            text: 'Something went wrong, try again in a moment. If the problem persists, please contact us',
                            showConfirmButton: false,
                            timer: 1500
                        }
                    );
                });
        },
        validateEmail(email) {
            this.emailValid = /^[^@]+@\w+(\.\w+)+\w$/.test(email)
        }
    },
    watch: {
        email(value) {
            this.email = value;
            this.validateEmail(value);
        }
    }

}

</script>
<style scoped>
.footer {
    background-color: var(--color-gray-1);
}

.section {
    padding: 2rem;
}

h5 {
    font-family: var(--font-1);
    font-size: var(--text-xs);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    padding-bottom: var(--vspace-0_75);
    margin-bottom: var(--vspace-0_75);
    margin-top: 0;
    border-bottom: 1px solid var(--color-gray-2);
    text-align: left;

}

ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    text-align: left;
}

.footer_list ul li a {
    color: var(--color-gray-10);
}

.footer_list ul li a:hover {
    color: var(--color-gray-16);
}

.btn,
button,
input[type="submit"].email-invalid-btn {
    opacity: 0.5;
    color: rgb(255, 255, 255);
    cursor: not-allowed;
}

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    --btn-height: var(--vspace-btn);
    display: inline-block;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: var(--text-xs);
    text-transform: uppercase;
    letter-spacing: .4em;
    height: var(--btn-height);
    line-height: calc(var(--btn-height) - .4rem);
    padding: 0 3.2rem;
    margin: 0 .4rem 1.6rem 0;
    color: var(--color-btn-text);
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-btn);
    border: 0.2rem solid var(--color-btn);
    width: 100%;
}

.subscribe-form input[type="email"] {
    --input-height: vspace(1.75);
    background-color: transparent;
    border: 1px solid var(--color-text);
    padding: .8rem 2rem;
    width: 100%;
    text-align: center;
    margin-bottom: var(--vspace-0_5);
    --input-line-height: var(--vspace-1);
    --input-vpadding: calc((var(--input-height) - var(--input-line-height)) / 2);
    display: block;
    height: var(--input-height);
}

.subscribe-form input[type="email"].email-invalid {
    border: 1px solid #5d1111;

}
</style>