<template>
    <div v-if="!disable" class="cookie-consent">
        <div class="cookie-text">
            <p>This website uses cookies to ensure you get the best experience on our website.</p>
        </div>
        <div class="toggle-switches">
            <div class="toggle-switch">
                <p>Strictly neccesary cookies</p>
                <input type="checkbox" id="switch1" v-model="switch1" />
                <label for="switch1">Toggle 1</label>
            </div>
            <div class="toggle-switch">
                <p>Preference cookies</p>
                <input type="checkbox" id="switch2" v-model="switch2" />
                <label for="switch2">Toggle 2</label>
            </div>
            <div class="toggle-switch">
                <p>All cookies</p>
                <input type="checkbox" id="switch3" v-model="switch3" />
                <label for="switch3">Toggle 3</label>
            </div>
        </div>
        <base-button @click="accept" type="light" :disabled="!switch1 && !switch2 && !switch3">
            Accept
        </base-button>
        <base-button @click="reject" type="light" :disabled="false">
            Reject
        </base-button>
    </div>
</template>
  
<script>
import BaseButton from "./BaseComponents/BaseButton.vue";

export default {
    components: { BaseButton },
    data() {
        return {
            disable: false,
            switch1: false,
            switch2: false,
            switch3: false,
        };
    },
    methods: {
        accept() {
            this.disable = true;
            if(this.switch3){
                localStorage.setItem("cookie_consent", "all");
            }
            if(this.switch2){
                localStorage.setItem("cookie_consent", "pref");
            }
            if(this.switch1){
                localStorage.setItem("cookie_consent", "neccesary");
            }
        }, 
        reject() {
            this.disable = true;
            console.log('reject')
            localStorage.setItem("cookie_consent", "deny");
        },
    },
    watch: {
        switch3: function () {
            this.switch1 = true
            this.switch2 = true
        }
    },
    mounted() {
        if (localStorage.getItem("cookie_consent")) {
            this.disable = true;
        }
    },
};
</script>
  
<style scoped>
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px 5px 0 0;
}

.cookie-text {
    margin-bottom: 15px;
}

.toggle-switches {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.toggle-switch {
    margin-right: 15px;
    display: flex;
}

.checkbox {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.checkbox input {
    opacity: 0;
    width: 0;
    height: 0;
}

input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label {
    cursor: pointer;
    text-indent: -9999px;
    width: 70px;
    height: 40px;
    background: var(--color-gray-10);
    display: block;
    border-radius: 100px;
    position: relative;
}

label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: var(--color-gray-1);
    border-radius: 90px;
    transition: 0.3s;
}

input:checked+label {
    background: var(--color-gray-6);
}

input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

label:active:after {
    width: 40px;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>
  