import { createStore } from "vuex";
// import posts from "../assets/posts/posts.json";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export default createStore({
  state: {
    contentType: "1",
    user: null,
    // posts: posts,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setContentType(state, type) {
      state.contentType = type;
    },
  },
  getters: {
    isSignIn: (state) => {
      return state.user !== null;
    },
    // getPostsCopy(state) {
    //   return JSON.parse(JSON.stringify(state.posts));
    // },
    getContentDescription(state) {
      switch (state.contentType) {
        case "1":
          return "Developer & Designer";
        case "2":
          return "Designer";
        case "3":
          return "Frontend-Developer";
        case "4":
          return "Backend-Developer";
        default:
          console.log(`Sorry, no valid state ${state.contentType}.`);
      }
    },
    getHeroImageName(state) {
      // const assetsContext = require.context(
      //   "@/assets/hero/",
      //   true,
      //   /\.(png|jpe?g|gif)$/
      // );
      //above will create a mapp like the following   var map = {
      //          "./Backend/1.jpg": "./src/assets/hero/Backend/1.jpg",
      //          "./Design/1.jpg": "./src/assets/hero/Design/1.jpg",
      //          "./Developer/1.jpg": "./src/assets/hero/Developer/1.jpg",
      //          "./Frontend/1.jpg": "./src/assets/hero/Frontend/1.jpg",
      //          "./Frontend/2.jpg": "./src/assets/hero/Frontend/2.jpg",
      //          "./heroImg1.jpg": "./src/assets/hero/heroImg1.jpg",
      //          "./heroImg2.jpg": "./src/assets/hero/heroImg2.jpg",
      //          "./heroImg3.jpg": "./src/assets/hero/heroImg3.jpg"
      //      };
      //console.log(assetsContext("./heroImg1.jpg"));
      const assetsContext = require.context('@/assets/hero', true, /\.(png|jpe?g|gif)$/);
      if (state.contentType === "1") {
        return "heroImg1.jpg";
      } else if (state.contentType === "2") {
        let designImages = assetsContext.keys().filter(key => key.startsWith('./Design'));
        shuffleArray(designImages);
        return designImages[0].replace(/^\.\//, ''); // Removes the first './';
      } else if (state.contentType === "3") {
        let frontendImages = assetsContext.keys().filter(key => key.startsWith('./Frontend'));
        shuffleArray(frontendImages);
        console.log(frontendImages[0].replace(/^\.\//, ''));
        return frontendImages[0].replace(/^\.\//, ''); // Removes the first './';
      } else if (state.contentType === "4") {
        let backendImages = assetsContext.keys().filter(key => key.startsWith('./Backend'));
        shuffleArray(backendImages);
        return backendImages[0].replace(/^\.\//, ''); // Removes the first './';
      } else {
        console.error(`Sorry, no valid state ${state.contentType}.`);
      }
    },
  },
  actions: {},
  modules: {},
});
