<template>
    <section class="grid-container wrapper">
        <div class="row">
            <div class="column large-12">
                <article class="s-content__entry">
                    <div class="s-content__entry-header">
                        <h1 class="s-content__title">Who.</h1>
                    </div>
                    <div class="s-content__primary">
                        <div class="s-content__page-content">
                            <p class="lead">
                                As a passionate and skilled software developer, I am driven by a strong
                                desire to create systems that make people's lives easier, more efficient, and more
                                enjoyable. Whether it's in the realm of education, leadership, or everyday tasks, I am
                                committed to using my extensive knowledge and expertise to bring innovative ideas to life.
                            </p>
                            <p>
                                With a background in software architecture and front-end development, I am well-versed in a
                                wide range of programming languages and technologies, including Java, C#, C++/C, JavaScript,
                                HTML, CSS, SQL, and many others. Whether I am building a complex backend system or designing
                                a sleek and user-friendly interface, I always strive to deliver the highest-quality results.
                            </p>
                            <p>
                                In addition to my technical skills, I also have experience working with 3D graphics and
                                graphic design, giving me a unique perspective on how software and visuals can work together
                                to create truly immersive experiences.
                            </p>
                            <p>
                                As a lifelong learner, I am always looking for new opportunities to expand my knowledge and
                                skills, whether it's through collaborating with others on exciting projects or taking on
                                challenging new projects on my own. If you are interested in working with me, or just want
                                to learn more about what I do, please don't hesitate to get in touch. I look forward to
                                hearing from you!
                            </p>
                            <p>
                                I currently work as a full-stack developer for a consulting company in Sweden, where I have
                                the opportunity to work on various projects for different clients. My work involves
                                everything from front-end design to back-end systems, and I enjoy learning new technologies
                                and collaborating with diverse teams to deliver high-quality solutions.
                            </p>
                            <br>
                        </div>
                    </div>
                    <div class="s-content__media">
                        <img src="../assets/hero/heroImg3.jpg">
                    </div> <!-- end s-content__media -->
                    <div class="s-content__entry-header">
                        <h1 class="s-content__title">The blog.</h1>
                    </div>
                    <div class="s-content__primary">
                        <div class="s-content__page-content">
                            <p class="lead">
                                As a software developer, I'm passionate about all aspects of
                                coding and development. This blog is where I share my insights, tips, and experiences
                                with other developers who are looking to improve their skills and advance their careers.
                            </p>
                            <p>
                                My blog covers a wide range of topics related to software development, including coding tips
                                and tricks, web development best practices, design principles for the web, soft skills for
                                software developers, and general advice aimed at helping developers succeed.
                            </p>
                            <p>
                                If you're looking to improve your coding skills, I share tips and tricks for writing
                                efficient and maintainable code, exploring new programming languages and frameworks, and
                                staying up-to-date with the latest trends in software development.
                            </p>
                            <p>
                                If you're looking to improve your soft skills as a software developer, I share practical
                                advice on communication, teamwork, time management, and other skills that are essential for
                                success in today's fast-paced and constantly evolving tech industry.
                            </p>
                            <p>
                                And finally, if you're simply looking for some general advice and inspiration, I share my
                                personal insights and experiences as a software developer, including lessons learned from my
                                own successes and failures, and tips for staying motivated and productive even in the face
                                of challenges.
                            </p>
                            <p>
                                So whether you're a seasoned developer or just getting started, my blog has something for
                                everyone. I hope you'll join me on this journey of growth, learning, and discovery.
                            </p>
                            <br>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<style scoped>
@media screen and (min-width: 1201px) {}

/* ------------------------------------------------------------------- 
 * ## medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {
    .s-content__entry-header {
        margin-top: var(--vspace-2);
        margin-bottom: var(--vspace-2);
    }

    .s-content__primary {
        padding: 2rem;
    }
}

/* ------------------------------------------------------------------- 
 * ## tablets
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {}

/* ------------------------------------------------------------------- 
 * ## mobile devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {

    h1,
    .h1 {
        font-size: var(--text-xxxl);
        line-height: var(--vspace-1_75);
    }
}

/* ------------------------------------------------------------------- 
 * ## small mobile devices <= 400px
 * ------------------------------------------------------------------- */
@media screen and (max-width: 400px) {
    .s-content__entry-header {
        margin-top: var(--vspace-1_5);
        margin-bottom: var(--vspace-1_5);
    }
}

h1,
.h1 {
    font-size: var(--text-display-1);
    line-height: calc(var(--vspace-1_75) + var(--vspace-0_125));
}

.wrapper {
    margin-top: 8.8rem;
}

.s-content__entry-header {
    margin-top: var(--vspace-3);
    margin-bottom: var(--vspace-3);
}

.s-content__title {
    margin-top: 0;
    letter-spacing: -.01em;
    text-align: center;
}</style>