<template>
    <div class="wrapper">
        <section>
            <div class="grid-x hero__content">
                <img class="hero_image" :src="getHeroImage()">
                <div class="cell large-9 value">
                    <span class="headline_text">Crafting Dynamic Frontend Experiences</span>
                    <h4 class="sub_text">I’m Tom, a frontend developer proficient in a wide range of web
                        technologies. Let's turn your vision into a dynamic, engaging reality! </h4>
                </div>
                <div class="cell large-1 hero__social hide-on-mobile">
                    <p>Follow</p>
                    <span></span>
                    <ul class="hero__social-icons">
                        <li><a href="https://www.linkedin.com/in/tom-andersson-4a7189113/" target=”_blank”><i
                                    class="fab fa-linkedin" aria-hidden="true"></i></a></li>
                        <li><a href="https://www.instagram.com/lifearray/" target=”_blank”><i class="fab fa-instagram"
                                    aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- <displacement-slider></displacement-slider>-->
    </div>
</template>
  
<script>
// eslint-disable-next-line
import BaseButton from "../BaseComponents/BaseButton.vue"

import DisplacementSlider from '../DisplacementSlider.vue'


export default {
    components: {
        // eslint-disable-next-line
        BaseButton,
        // eslint-disable-next-line 
        DisplacementSlider
    },
    data() {
        return {
            show: true
        }
    },
    methods: {
        getHeroImage() {
            return require(`@/assets/hero/frontendhero.png`);
        },
        goto(refName) {
            var el = this.$refs[refName];
            if (el) {
                this.show = !this.show;
                el.scrollIntoView({ behavior: "smooth" });
            }
        }
    },
}
</script>
  
<style scoped>
.wrapper {
    margin-top: 7.5rem;
    scroll-behavior: smooth;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: var(--text-xl);
}

h4 {
    font-size: var(--text-md);
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.value {
    padding: 20rem;
}

.hero_image {
    position: absolute;
    left: 46rem;
    max-height: 100vh;
}

.hero__content {
    height: 100vh;
    /* background: linear-gradient(rgba(28,106,127, 1), rgba(247,189,144, 1)) ; */
    /* background: radial-gradient(rgba(28,106,127, 1), rgba(247,189,144, 1)); */
    background-color: var(--color-gray-17);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/heroImg5.jpg'); */
}

.headtext {
    color: var(--color-gray-1);
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 5.8rem;
    letter-spacing: +.05em;
    display: block;
    font-weight: 200;
}

.headline_text {
    color: var(--color-gray-1);
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 5.8rem;
    letter-spacing: +.15em;
    font-weight: 500;
    max-width: 80vw;
}

.sub_text {
    color: var(--color-gray-2);

}

section {
    width: 100%;
    min-height: 100vh;
    /* min-height: calc(25.5 * var(--space)); */
    border-bottom: 1px solid rgb(98, 98, 98);
    /* Adjust the color and thickness as needed */
    background-color: #f9f8eb;
}


.hero__social {
    font-size: var(--text-xs);
    font-weight: 400;
    color: var(--color-gray-19);
    position: absolute;
    top: 40%;
    right: 0;
}

.hero__social a {
    color: var(--color-gray-1);
}

.hero__social span {
    display: block;
    width: 1px;
    height: 3.6rem;
    background-color: var(--color-gray-19);
    margin: 3.6rem auto 4.8rem;
}

.hero__social p {
    line-height: 1;
    margin: 0;
    position: relative;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.hero__social-icons {
    list-style: none;
    margin-left: 0;
    font-size: 2.4rem;
    color: var(--color-gray-1);
}

.hero__social-icons a i:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.hero__social-icons a i {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    color: var(--color-gray-1);
}

.hero__social-icons li {
    padding-left: 0;
    margin-bottom: var(--vspace-0_75);
    text-align: center;
}


/*vue animation*/
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
    transition: all .3s ease-in;
    transform: translateX(100vw);
}

.fade-enter-to {
    transform: translateX(0px);

}

.fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.fade-enter,
.fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}
</style>